<template>
    <div class="card" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th></th>
                    <th>Party Name</th>
                    <th>Bill Date</th>
                    <th>Bill NO</th>
                    <th class="text-right">Bill Amount</th>
                    <th class="text-right">Already Paid</th>
                    <th class="text-right">Due</th>
                    <th class="text-right">Payment</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(item, i) in payBills" :key="i">
                    <BillPayTableRow :item="item" />
                </template>
                <tr>
                    <td colspan="7" class="text-right"><h4>Total Payment</h4></td>
                    <td class="text-right">
                        {{total}}
                    </td>
                </tr>
                
                </tbody>
            </table>
            <!-- <p v-if="!purchase.length" class="text-center mt-5">No data</p> -->
        </div>
    </div>
</template>

<script setup>
import BillPayTableRow from '@/components/molecule/company/inventory/sales/BillPayTableRow.vue'
    import { inject, computed } from 'vue'
    // const payBills = inject('payBills')
    const props = defineProps({
        payBills: Array,
    })

    const total = computed(() => {
        let sum = 0
         props.payBills.forEach(i => {
             sum+= i.payment !== '' ? parseFloat(i.payment) : 0
         })
        return sum
    })
</script>
