<template>
  <div>
    <div class="card p-2">
      <TitleButton
        :showBtn="true"
        title="Money Receipt Edit"
        :showAddNew="false"
        @onClickCloseButton="navigateToListPage"
      />

      <div class="row gy-1 pt-75 bg-blue-light rounded-8 mt-1 pb-2">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Customer</label>
          <v-select
            v-model="selectedContact"
            placeholder="Select Party"
            class="w-100"
            label="name"
            @option:selected="onchangeContact"
            :options="contacts"
            :reduce="(name) => name.id"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Received Date</label>
          <input
            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
            tabindex="1"
            type="date"
            placeholder=""
            v-model="salesFormData.receipt_date"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Voucher No</label>
          <div class="input-group input-group-merge invoice-edit-input-group">
            <div class="input-group-text">
              <span>{{ prefix }}-</span>
            </div>
            <input
              type="number"
              min="1"
              class="form-control invoice-edit-input"
              placeholder=""
              v-model="serial"
            />
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Received In</label>
          <v-select
            v-model="salesFormData.account_head_id"
            placeholder="Select Cash & Bank Account"
            class="w-100"
            :options="accountHeads"
            label="name"
            :reduce="(name) => name.id"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Received Ref</label>
          <input
            type="hidden"
            class="form-control invoice-edit-input date-picker flatpickr-input"
            readonly="readonly"
          />
          <div class="input-group input-group-merge invoice-edit-input-group">
            <div class="input-group-text">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-hash"
              >
                <line x1="4" y1="9" x2="20" y2="9"></line>
                <line x1="4" y1="15" x2="20" y2="15"></line>
                <line x1="10" y1="3" x2="8" y2="21"></line>
                <line x1="16" y1="3" x2="14" y2="21"></line>
              </svg>
            </div>
            <input
              v-model="salesFormData.receipt_reference"
              type="text"
              class="form-control invoice-edit-input"
              placeholder=""
            />
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">R.Ref Date</label>
          <input
            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
            tabindex="1"
            type="date"
            placeholder=""
            v-model="salesFormData.receipt_reference_date"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Business</label>
          <v-select
            placeholder="Select Business"
            class="w-100"
            :options="business"
            label="name"
            :reduce="(name) => name.id"
          />
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Balance</label>
          <input
            type="hidden"
            class="form-control invoice-edit-input date-picker flatpickr-input"
            readonly="readonly"
          />
          <div class="input-group input-group-merge invoice-edit-input-group">
            <input
              v-model="balance"
              readonly
              type="text"
              class="form-control invoice-edit-input"
              placeholder=""
            />
          </div>
        </div>
      </div>

      <div class="mt-2"></div>
      <BillPayTable :payBills="invoiceDueBills" />

      <div class="row mt-1">
        <div class="col-12">
          <div class="mb-2">
            <label for="note" class="form-label fw-bold">Memo</label>
            <textarea
              v-model="salesFormData.description"
              placeholder="Memo"
              class="form-control"
              rows="2"
              id="note"
            ></textarea>
          </div>
        </div>
      </div>

      <div class="d-flex gap-1">
        <button
          :disabled="saveButtonLoader"
          @click="payBill()"
          class="btn btn-primary"
        >
          Update
        </button>
        <button
          @click="navigateToListPage"
          class="btn btn-outline-secondary cancel-btn"
        >
          Cancel
        </button>
      </div>
    </div>
    <GlobalLoader />
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import TitleButton from "@/components/atom/TitleButton";
import BillPayTable from "@/components/molecule/company/inventory/purchase/BillPayTable";
import useBillPayData from "@/data/inventory/billPay.js";

import handlePurchaseAndSales from "@/services/modules/purchase";
import {editVoucherNumber, generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
const { salesFormData, fetchMoneyReceipt, updateMoneyReceipt } = handlePurchaseAndSales();
const route = useRoute();
const router = useRouter();
const voucherQuery = `?company_id=${route.params.companyId}&voucher_type=payment_voucher&txn_type=bill_payment`;
const {
    getInvoiceDueList,
    getAccountHead,
    getContactProfiles,
    getBusinessList,
    accountHeads,
    contacts,
    balance,
    business,
    prefix,
    serial,
    invoiceDueBills,
    params,
    payment
} = useBillPayData(voucherQuery);

const saveButtonLoader = ref(false);
const showError = inject("showError");
const showSuccess = inject("showSuccess");
const selectedContact = ref(null);

onMounted(async () => {
  getAccountHead();
  getContactProfiles();
  getBusinessList();
  await fetchMoneyReceipt(route.params.receiptId, `?company_id=${params.companyId}`)
    .then(res => {
      if(res.status) {
        salesFormData.value = res.data;
        salesFormData.value.receipt_date = res.data.mr_receipt_date
        salesFormData.value.receipt_reference_date = res.data.mr_receipt_reference_date
        invoiceDueBills.value = salesFormData.value.general;
        let voucher = editVoucherNumber(salesFormData.value.voucher_no);
        prefix.value = voucher.prefix;
        serial.value = voucher.serial;
      }
    })
  selectedContact.value = salesFormData.value.contact_profile_id;
});
function setRefDate(e) {
  salesFormData.value.payment_reference_date = e.target.value;
}
function navigateToListPage() {
  router.push({ name: "money-receipt-list", params: params, query: route.query });
}
function onchangeContact(e) {
  getInvoiceDueList(e.id);
}

function payBill() {
  saveButtonLoader.value = true;
  salesFormData.value.contact_profile_id = selectedContact.value;
  salesFormData.value.voucher_no = prefix.value + "-" + serial.value;
  salesFormData.value.transactions = JSON.stringify(invoiceDueBills.value);
  updateMoneyReceipt(route.params.receiptId, salesFormData.value)
    .then((res) => {
      saveButtonLoader.value = false;
      if (res.status) {
          navigateToListPage();
      }
      if (!res.status) {
        showError(res.message);
      }
    })
    .catch((err) => {
      saveButtonLoader.value = false;
    });
}
</script>
