<template>
    <tr>
        <td></td>
        <td>{{ item.supplier?.name }}</td>
        <td> {{ item.date }}</td>
        <td> {{ item.bill_number }}</td>
        <td class="text-right"> {{ item.total_amount }}</td>
        <td class="text-right"> {{ item.paid_amount }}</td>
        <td class="text-right"> {{ item.due }}</td>
        <td class="text-right">
            <div class="d-flex justify-content-end">
                <input
                    v-model="item.payment"
                    @input="checkInputValidity"
                    type="number" class="form-control text-right"
                    style="width: 100px"
                >
            </div>
        </td>
    </tr>
</template>

<script setup>
const props = defineProps({
    item: Object
})

function checkInputValidity (e) {
    if(e.target.value > props.item.due) {
        props.item.payment = props.item.due
    }
}
</script>